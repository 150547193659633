import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useLocationsList() {
    const toast = useToast()
    const records = ref(null)

    // Table Handler
    const columns = [
        { key: 'name', sortable: true },
        { key: 'code', sortable: true},
        { key: 'parent', label: 'From', sortable: false},
        'actions',
    ]

    const from = ref(0)
    const to = ref(0)
    const meta = ref({ from: 0, to: 0, total: 0 })
    const perPage = ref(10)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('name')
    const isSortDirDesc = ref(true)
    const level_id = ref(null)
    const location_id = ref(null)

    const refetch = () => {
        records.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, sortBy, isSortDirDesc, level_id, location_id], () => {
        refetch()
    })

    const fetch = async (ctx, callback) => {
        if(level_id.value === null){
            store
                .dispatch('cromis-location/levels', {})
                .then(response => {
                    const { levels } = response.data
                    level_id.value = levels[0].id
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error get a level',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                })
        }

        if(level_id.value !== null){
            store
                .dispatch('cromis-location/list', {
                    level_id: level_id.value,
                    location_id: location_id.value,
                    query: searchQuery.value,
                    per_page: perPage.value,
                    page: currentPage.value,
                    sortBy: sortBy.value,
                    sortDesc: isSortDirDesc.value,
                })
                .then(response => {
                    const { locations, meta } = response.data

                    meta.value = meta

                    callback(locations)
                    totalRecords.value = meta.value.total
                    from.value = meta.value.from
                    to.value = meta.value.to
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error fetching locations list',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                })
        }
    }

    return {
        records,
        fetch,
        refetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        level_id,
        location_id,
    }
}