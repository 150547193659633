<template>
    <div>
            <!-- Form Modal -->
            <b-modal
                id="modal-season-form"
                ref="myModal"
                centered
                no-close-on-backdrop
                no-close-on-esc
                @ok="submit"
                size="sm"
            >
                <!-- Custom Modal Header -->
                <template #modal-header="{ close }">
                    <!-- Emulate built in modal header close button action -->
                    <h5 v-if="location.id === null">
                        <span v-if="!filtered && filters.length > 0">Register {{ filters[0].label }}</span>
                        <span v-else>Register {{ filtered.level.children[0].name }}</span>
                    </h5>
                    <h5 v-if="location.id !== null">
                        <span v-if="!filtered && filters.length > 0">Edit {{ filters[0].label }}</span>
                        <span v-else>Edit {{ filtered.level.children[0].name }}</span>
                    </h5>
                    <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
                </template>

                <!-- Custom Modal Footer -->
                <template #modal-footer="{ ok, cancel }">
                    <b-button size="sm" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                    </b-button>
                    <b-button size="sm" @click="cancel()" variant="outline-secondary"> Cancel </b-button>
                </template>

            <!-- Data Form in Modal Body -->
            <template #default="{}">
                <validation-observer ref="dataForm" #default="{ invalid }">
                <b-form ref="form" @submit.stop.prevent="handleCreate">
                    <b-row v-if="filtered">
                        <b-col cols="12">
                            <b-form-group label-for="location_id" :label="'Under ' + filtered.level.name">
                            <validation-provider #default="{ errors }" name="location_id" rules="">
                                <b-form-select
                                    size="sm"
                                    v-model="location.location_id"
                                    id="location_id"
                                    name="location_id"
                                    :state="errors.length > 0 ? false : null"
                                >
                                    <b-form-select-option :value="filtered.id" selected>{{ filtered.name }}</b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{ serverErrors.location_id[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" md="3" v-if="filtered && filtered.level.children[0].coded">
                            <b-form-group label-for="code" label="Code" style="cursor: pointer">
                            <validation-provider #default="{ errors }" name="code" rules="">
                                <b-form-input
                                    id="code"
                                    name="code"
                                    size="sm"
                                    v-model="location.code"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.code">{{ serverErrors.code[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="3" v-if="!filtered && filters.length > 0 && filters[0].coded">
                            <b-form-group label-for="code" label="Code" style="cursor: pointer">
                            <validation-provider #default="{ errors }" name="code" rules="">
                                <b-form-input
                                    id="code"
                                    name="code"
                                    size="sm"
                                    v-model="location.code"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.code">{{ serverErrors.code[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" :md="(filtered && filtered.level.children[0].coded)? 9 : ((!filtered && filters.length > 0 && filters[0].coded)? 9 : 12)">
                            <b-form-group label-for="name" label="Name">
                                <validation-provider #default="{ errors }" name="name" rules="">
                                    <b-form-input
                                        id="name"
                                        name="name"
                                        size="sm"
                                        v-model="location.name"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <!-- <pre>
                        {{ location }}
                    </pre> -->
                </b-form>
                </validation-observer>
            </template>
            </b-modal>
            <!-- ./Form Modal -->


        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Locations Filter</h5>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col
                        cols="12"
                        md="2"
                        class="mb-md-0 mb-2"
                        v-for="(filter, index) in filters.filter((e) => e.child != null)"
                        :key="filter.level"
                    >
                        <label :label-for="filter.level">{{ filter.label }}</label>
                        <b-form-select
                            :id="filter.level"
                            :name="filter.lavel"
                            size="sm"
                            v-model="filter.model"
                            @change="changeFilters(filter.model, index)"
                        >
                        <b-form-select-option :value="null">Select...</b-form-select-option>
                        <b-form-select-option
                            v-for="location in filter.locations"
                            :key="location.id"
                            :value="location"
                        >
                            {{ location.name.toUpperCase() }}
                        </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>

                <!-- <pre>[{{ filtered }}]</pre> -->
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                            <b-button variant="primary" size="sm" @click="invokeCreateForm">
                                <span class="text-nowrap" v-if="filtered">Register {{ filtered.level.children[0].name }}</span>
                                <span class="text-nowrap" v-else-if="filters.length > 0">Register {{ filters[0].label }}</span>
                                <span class="text-nowrap" v-else>Register</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                :busy="isBusy"
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
            <template #cell(parent)="data">
                <span v-if="data.item.parent">{{ data.item.parent.name }} {{ data.item.parent.level.name }}</span>
            </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,

    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    BTable,
    BPagination,
    BForm,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,
    BBadge
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { required } from '@validations'
  import store from '@/store'
  import { ref, onUnmounted, onMounted } from '@vue/composition-api'
  import locationsStoreModule from '@/views/cromis/locations/locationsStoreModule'
  import useLocationsList from '@/views/cromis/locations/useLocationsList'
  
  export default {
    props: {},
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BCardText,

      BMedia,
      BMediaAside,
      BAvatar,
      BMediaBody,

      BTable,
      BPagination,
      BForm,
      BFormGroup,
      BButton,
      BFormSelect,
      BFormSelectOption,
      BFormInput,
      vSelect,
      BDropdown,
      BDropdownItem,
      BFormDatepicker,
      BFormCheckbox,
      BSpinner,
      BBadge,
      ValidationObserver,
      ValidationProvider,
    },

    directives: {},
    setup(props, context) {
        const dataForm = ref(null);
        const myModal = ref(null);
        const saving = ref(false);
        const serverErrors = ref(null);

        const levels = ref([])
        const locations = ref([])

        const filters = ref([])
        const filtered = ref(null)
        const children = ref([])

        const under = ref(null)

        const location = ref({
            id: null,
            code: null,
            name: null,

            location_id: null,
            level_id: filtered.value != null? filtered.value.level.children[0].id : (filters.value.length > 0? filters.value[0].id : null),
        })
  
        const CROMIS_STORE_MODULE_NAME = "cromis-location";
  
      // Register module
      if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) {
        store.registerModule(CROMIS_STORE_MODULE_NAME, locationsStoreModule)
      }
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(CROMIS_STORE_MODULE_NAME)) {
          store.unregisterModule(CROMIS_STORE_MODULE_NAME);
        }
      });
  
      onMounted(async () => {
        await store.dispatch('cromis-location/levels')
                .then(response => {
                    levels.value = response.data.levels

                    if(levels.value.length > 0){
                        levels.value = levels.value[0]
                
                        filters.value.push({
                            id: levels.value.id,
                            level: levels.value.name.toLowerCase(),
                            coded: levels.value.coded,
                            model: null,
                            label: levels.value.name,
                            child: levels.value.children[0].name.toLowerCase(),
                            locations: [], 
                        })
                
                        let list = levels.value.children
                
                        while(list.length > 0){
                            children.value.push(list[0])
                
                            filters.value.push({
                                id: list[0].id,
                                level: list[0].name.toLowerCase(),
                                coded: list[0].coded,
                                model: null,
                                label: list[0].name,
                                child: list[0].children.length > 0? list[0].children[0].name.toLowerCase() : null,
                                locations: [],
                            })
                
                            list = list[0].children
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                })

        await store.dispatch('cromis-location/list', { all: true })
                    .then(response => {
                        locations.value = response.data.locations
                        setFiltersLocations(locations.value)
                    })
                    .catch(error => {
                        console.log(error)
                    })
      });

    const setFiltersLocations = (locations) => {
        for(let i = 0; i < filters.value.length; i++){
            filters.value[i].locations.splice(0)
        }

        for(let i = 0; i < locations.length; i++){
            for(let x = 0; x < filters.value.length; x++){
                if(locations[i].level.name.toUpperCase() == filters.value[x].level.toUpperCase()){
                    filters.value[x].locations.push(locations[i])
                }
            }
        }
    }

    const changeFilters = async (filteredLocation, index) => {
        if(!filteredLocation){
            for(let i = index + 1; i < filters.value.length; i++){
                filters.value[i].locations.splice(0)
                filters.value[i].model = null
            }

            if(index > 0){
                filtered.value = filters.value[index - 1].model
            }
            else{
                filtered.value = null
            }

            location_id.value = null
        }
        else{
            if(filters.value[index + 1]){
                filters.value[index + 1].locations.splice(0)
            }

            filteredLocation.children.map((child) => {
                filters.value[index + 1].locations.push(child)
            })

            if(filteredLocation.children.length > 0){
                filters.value[index + 1].model = null
            }

            filtered.value = filters.value[index].model

            location_id.value = filteredLocation.id
            level_id.value = filtered.value.level.children[0].id
        }
    }


    const {
        fetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,
        refetch,
        isBusy,

        // Filers
        location_id,
        level_id,

        // Resolvers
        resolveStatusVariant,
    } = useLocationsList()

    const invokeUpdateForm = (item) => {
      serverErrors.value = null;

      location.value = {
        id: item.id,
        name: item.name,
        code: item.code,

        location_id: item.parent != null? item.parent.id : null,
        level_id: filtered.value != null? filtered.value.level.children[0].id : (filters.value.length > 0? filters.value[0].id : null),
      };

      myModal.value.show();
    };

    const invokeCreateForm = () => {
      serverErrors.value = null;

      location.value = {
        id: null,
        name: null,
        code: null,

        location_id: filtered.value != null? filtered.value.id : null,
        level_id: filtered.value != null? filtered.value.level.children[0].id : (filters.value.length > 0? filters.value[0].id : null),
      }

      myModal.value.show();
    };

    const isFormValid = async () => {
      let isValid = false;
      await dataForm.value.validate().then((success) => {
        isValid = success;
      });
      return isValid;
    };

    const submit = (bvModalEvt) => {
      bvModalEvt.preventDefault();
      serverErrors.value = null;
      // Handle form submit
      if (location.value.id === null || location.value.id === 0) {
        handleCreate();
      } else {
        handleUpdate(location.value);
      }
    };

    const handleCreate = async () => {
      saving.value = true;

      const valid = await isFormValid();
      if (!valid) {
        saving.value = false;
        return;
      }

      await store
        .dispatch("cromis-location/create", location.value)
        .then((response) => {
          refetch();
          saving.value = false;
          myModal.value.hide();

          context.root.$swal({
            icon: "success",
            text: `Location ${response.data.name} registered successfully!`,
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });

          store.dispatch('cromis-location/list', { all: true })
                    .then(response => {
                        locations.value = response.data.locations
                        setFiltersLocations(locations.value)
                    })
                    .catch(error => {
                        console.log(error)
                    })
        })
        .catch((error) => {
          saving.value = false;
          if (error.response.status === 422) {
            serverErrors.value = error.response.data.errors;
          } else {
            context.root.$swal({
              icon: "error",
              title: "Server Error",
              text: "Something went wrong. See tech support",
              showConfirmButton: true,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    };
  
    const handleUpdate = async (item) => {
        saving.value = true;
        const valid = await isFormValid();

        if (!valid) {
            saving.value = false;
            return;
        }

        await store
            .dispatch("cromis-location/update", { id: item.id, data: item })
            .then((response) => {
                refetch();
                saving.value = false;

                myModal.value.hide();

                context.root.$swal({
                    icon: "success",
                    text: `Changes to location # ${response.data.name} has been saved successfully!`,
                    showConfirmButton: true,
                    timer: 3000,
                    customClass: {
                    confirmButton: "btn btn-success",
                    },
                    buttonsStyling: false,
                });

                store.dispatch('cromis-location/list')
                    .then(response => {
                        locations.value = response.data.locations
                        setFiltersLocations(locations.value)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch((error) => {
            saving.value = false;
            if (error.response.status === 422) {
                serverErrors.value = error.response.data.errors;
            } else {
                context.root.$swal({
                icon: "error",
                title: "Server Error",
                text: "Something went wrong. See tech support",
                showConfirmButton: true,
                customClass: {
                    confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
                });
            }
        });
    }
  
      return {
        // Data
        dataForm,
        myModal,

        filters,
        filtered,
        levels,
        under,

        location,

        // For table
        fetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,
        refetch,
        isBusy,
  
        // Methods
        setFiltersLocations,
        changeFilters,
        required,
        saving,
        serverErrors,
        handleUpdate,
        handleCreate,
        invokeCreateForm,
        invokeUpdateForm,
        submit,
        isFormValid,
      };
    },
  };
</script>
  
<style lang="scss" scoped>
    @import "@core/scss/vue/libs/vue-select.scss";
</style>
